import React from 'react';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';
import { Testimonial as TestimonialT } from '@/types';

type TestimonialProps = {
  testimonial: TestimonialT;
};

const Testimonial = ({ testimonial }: TestimonialProps): JSX.Element => {
  const { t } = useTranslation();
  const { fullName, company, picture, content, link } = testimonial;

  return (
    <div className="my-[100px] lg:my-[150px] flex flex-col items-center text-center max-w-main mx-auto">
      <GatsbyImage
        image={getImage(picture)}
        objectFit="cover"
        alt="testimonial pic"
        className="w-[140px] h-[140px] border-8 border-black dark:border-white rounded-full"
        imgClassName="rounded-full"
      />
      <div className="text-2xl lg:text-3xl italic my-[25px] lg:my-[50px] whitespace-pre-line">{`"${t(
        content,
      )}"`}</div>
      <a
        className="text-xl lg:text-2xl text-primary underline underline-offset-2"
        href={`https://www.${link}`}
        target="_blank"
        rel="noreferrer"
      >
        {fullName} - {company}
      </a>
    </div>
  );
};

export default Testimonial;
