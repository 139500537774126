import React from 'react';
import classNames from 'classnames';

interface AerialProps {
  children: JSX.Element | JSX.Element[];
  className?: string;
}

const Aerial: React.FC<AerialProps> = ({ children, className = '' }) => (
  <div
    className={classNames(
      `lg:text-[18px] 2xl:text-[20px] xl:leading-[32px] ${className}`,
      'text-base tracking-[0.52px]',
    )}
  >
    {children}
  </div>
);

export default Aerial;
