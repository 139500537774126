import React, { useCallback, useMemo, useRef, useState } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';

import ArrowIcon from '@/assets/svg/arrow.svg';
import useScrollCallback from '@/hooks/useScrollCallback';

const colors = [
  'text-greenLighter',
  'text-primaryLighter',
  'text-yellow',
  'text-green',
  'text-orange',
];

const icons = ['brief', 'paint', 'tools', 'science', 'cog'];

const Steps = () => {
  const ref = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [height, setHeight] = useState(0);
  const { t } = useTranslation();
  const data = useStaticQuery(graphql`
    query {
      brief: file(relativePath: { eq: "brief.webp" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      paint: file(relativePath: { eq: "paint.webp" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      tools: file(relativePath: { eq: "tools.webp" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      science: file(relativePath: { eq: "science.webp" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
      cog: file(relativePath: { eq: "cog.webp" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  `);

  const steps = useMemo(
    () =>
      Array(5)
        .fill(null)
        .map((_, i) => ({
          title: t(`step${i + 1}Title`),
          content: t(`step${i + 1}Content`),
          color: colors[i],
          img: data[icons[i]],
        })),
    [t],
  );

  useScrollCallback(
    useCallback(() => {
      setHeight(
        (window.innerHeight -
          containerRef.current.getBoundingClientRect().y -
          400) /
          containerRef.current.getBoundingClientRect().height,
      );
    }, [setHeight]),
  );

  return (
    <div
      className={classNames(
        'py-[60px] px-[30px] my-[75px] shadow-cbig rounded-main flex gap-12',
        'lg:py-[130px] lg:px-[100px] lg:my-[100px]',
      )}
    >
      <div className="flex-1" ref={containerRef}>
        {steps?.map(({ title, content, color, img }, i) => (
          <div
            key={i}
            className={classNames(
              'flex flex-col lg:flex-row lg:justify-between',
              {
                'mb-[60px] lg:mb-[120px]': i !== steps.length - 1,
              },
            )}
          >
            <div
              className={classNames(
                'font-heading text-[120px]',
                'lg:text-[240px] lg:leading-[0.85] lg:min-w-[400px] lg:text-center',
                color,
              )}
            >
              <div className="relative w-fit inline-block">
                {`0${i + 1}`}
                <div className="absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[60%] flex items-center">
                  <GatsbyImage
                    alt={icons[i]}
                    image={getImage(img)}
                    className="w-full"
                  />
                </div>
              </div>
            </div>
            <div>
              <div className="text-2xl lg:text-6xl font-heading">{title}</div>
              <div className="subcontent mt-8">{content}</div>
            </div>
          </div>
        ))}
      </div>
      <div
        ref={ref}
        style={{
          height: `${
            height *
              (containerRef.current?.getBoundingClientRect().height || 0) +
            200
          }px`,
          maxHeight: containerRef.current?.getBoundingClientRect().height,
        }}
        className="sticky min-h-[200px] self-end bottom-[200px] w-[12px] right-0"
      >
        <span
          style={{
            height: `${
              height *
                (containerRef.current?.getBoundingClientRect().height || 0) +
              125
            }px`,
            maxHeight:
              (containerRef.current?.getBoundingClientRect().height || 0) - 75,
          }}
          className="absolute min-h-[125px] top-0 left-1/2 -translate-x-1/2 w-[10px] bg-primary"
        />
        <ArrowIcon
          className="rotate-90 absolute bottom-[30px] left-1/2 -translate-x-1/2 fill-primary"
          height={30}
        />
      </div>
    </div>
  );
};

export default Steps;
