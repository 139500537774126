import classNames from 'classnames';
import React, { useEffect, useMemo } from 'react';
import { useSpring, animated, easings } from 'react-spring';
import MouseEffectImg, { MouseEffectImgProps } from '../MouseEffectImg';

const paths = [
  'M46.2,-79.4C59.9,-72,71.3,-59.9,78.8,-45.8C86.3,-31.8,89.9,-15.9,90.1,0.1C90.3,16.1,87,32.2,79.7,46.5C72.3,60.8,60.9,73.2,46.9,81.4C32.9,89.6,16.5,93.7,0.7,92.5C-15.1,91.4,-30.3,85.1,-44.4,76.9C-58.5,68.8,-71.6,58.8,-80.7,45.7C-89.7,32.6,-94.8,16.3,-93.4,0.8C-92,-14.7,-84.1,-29.3,-75,-42.4C-65.9,-55.5,-55.6,-67,-42.9,-75C-30.2,-83,-15.1,-87.4,0.5,-88.4C16.2,-89.3,32.4,-86.7,46.2,-79.4Z',
  'M45.2,-77.5C59.4,-70.2,72.2,-59.6,79.7,-46.2C87.1,-32.7,89.3,-16.4,87.9,-0.8C86.5,14.7,81.6,29.5,73.9,42.6C66.2,55.6,55.7,67,43,75.2C30.2,83.4,15.1,88.4,-0.6,89.5C-16.4,90.7,-32.7,87.9,-46.1,80C-59.4,72.1,-69.7,59.1,-77.5,45C-85.3,30.8,-90.7,15.4,-90.4,0.2C-90,-15,-84,-30,-75.4,-42.8C-66.8,-55.7,-55.6,-66.2,-42.6,-74.2C-29.6,-82.2,-14.8,-87.6,0.4,-88.3C15.5,-88.9,31.1,-84.8,45.2,-77.5Z',
  'M43,-74.1C56.7,-66.6,69.4,-57,77,-44.3C84.7,-31.6,87.2,-15.8,87,-0.1C86.8,15.6,84,31.2,76.5,44.2C69,57.1,56.9,67.3,43.4,75.6C29.8,83.9,14.9,90.2,-0.8,91.5C-16.5,92.9,-32.9,89.2,-47.1,81.3C-61.2,73.3,-73,61.2,-79.4,46.9C-85.8,32.6,-86.9,16.3,-86.8,0.1C-86.7,-16.2,-85.4,-32.4,-78.3,-45.5C-71.3,-58.6,-58.4,-68.7,-44.3,-76C-30.3,-83.3,-15.2,-87.9,-0.2,-87.4C14.7,-87,29.3,-81.6,43,-74.1Z',
  'M45.6,-79.8C58.7,-71.3,68.6,-58.4,76.5,-44.3C84.4,-30.3,90.3,-15.1,91.1,0.5C92,16.1,87.9,32.2,79.4,45.3C70.9,58.3,58,68.2,44,76.5C30.1,84.7,15,91.3,0.1,91.2C-14.9,91.1,-29.8,84.2,-42.8,75.4C-55.9,66.6,-67.2,56,-76.2,43.1C-85.3,30.2,-92.1,15.1,-92,0C-92,-15,-85.1,-30.1,-75.9,-42.7C-66.7,-55.4,-55.2,-65.6,-42.2,-74.1C-29.2,-82.6,-14.6,-89.4,0.8,-90.9C16.2,-92.3,32.5,-88.3,45.6,-79.8Z',
];

interface AnimatedBlob {
  url?: string;
  image?: MouseEffectImgProps;
  classNameImg?: string;
  displayImage?: boolean;
  blobFirstColor: string;
  blobSecondColor: string;
}

const AnimatedBlob: React.FC<AnimatedBlob> = ({
  url,
  image,
  classNameImg,
  displayImage,
  blobFirstColor,
  blobSecondColor,
}) => {
  const props = useSpring({
    to: { opacity: displayImage ? 1 : 0 },
    from: { opacity: !displayImage ? 1 : 0 },
    config: {
      duration: 500,
    },
  });

  useEffect(() => {
    props.opacity.start();
  }, [displayImage]);

  const [{ x }] = useSpring(() => ({
    from: {
      x: 0,
    },
    to: {
      x: paths.length,
    },
    loop: {
      reverse: true,
    },
    config: {
      duration: 15000,
      easing: easings.easeInOutQuad,
    },
  }));

  const svgPath = useMemo(
    () => (
      <animated.path
        transform="translate(100 100)"
        width="120%"
        height="120%"
        vectorEffect="non-scaling-stroke"
        fill="url(#MyGradient)"
        style={props}
        d={x.to({
          range: Array(paths.length)
            .fill(null)
            .map((_, i) => i),
          output: paths,
        })}
      />
    ),
    [x, paths],
  );

  return (
    <div className="relative">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="450"
        height="450"
        viewBox="0 0 195 195"
      >
        <defs>
          <clipPath id="shape">{svgPath}</clipPath>
        </defs>
        <image
          clipPath="url(#shape)"
          xlinkHref={url}
          width="100%"
          style={{
            transform: 'translate(95px 95px)',
            opacity: displayImage ? 0 : 1,
            transition: 'opacity 0.5s ease',
          }}
        />
        {svgPath}
        <defs>
          <linearGradient id="MyGradient" gradientTransform="rotate(90)">
            <stop offset="5%" stopColor={blobFirstColor || '#926FF9'} />
            <stop offset="95%" stopColor={blobSecondColor || '#C8BAF4'} />
          </linearGradient>
        </defs>
      </svg>
      {image && (
        <div
          className={classNames(
            'absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 w-[70%] h-[70%] opacity-1 transition-opacity duration-500 flex items-center justify-center',
            displayImage ? 'opacity-1' : 'opacity-0',
            classNameImg,
          )}
        >
          <MouseEffectImg {...image} />
        </div>
      )}
    </div>
  );
};

export default AnimatedBlob;
