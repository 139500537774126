import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { getImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';

import { tradWithColors } from '@/utils/trad';

import Hero from '@/components/Hero';

const HerMethodo = () => {
  const { t } = useTranslation();

  const htmlContent = useMemo(() => tradWithColors(t('heroTitle')), []);

  const data = useStaticQuery(graphql`
    query {
      chat: file(relativePath: { eq: "search.png" }) {
        childImageSharp {
          gatsbyImageData(placeholder: BLURRED)
        }
      }
    }
  `);

  return (
    <Hero
      fullHeight
      title={htmlContent}
      subTitle={<div>{t('heroSubtitle')}</div>}
      image={{
        image: getImage(data.chat),
        alt: 'search icon',
      }}
    />
  );
};

export default HerMethodo;
