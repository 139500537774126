import React, { useRef, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { tradWithColors } from '@/utils/trad';
import useScrollCallback from '@/hooks/useScrollCallback';

import Milestones from '@/assets/img/superjalonsclub.svg';
import MilestoneBar from '@/assets/img/milestone-bar.svg';

const FixedBottomImage = () => {
  const { t } = useTranslation();
  const [milestoneBarPosition, setMilestoneBarPosition] =
    useState<number>(-240);
  const containerRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);

  useScrollCallback(
    useCallback(() => {
      const { y } = containerRef.current.getBoundingClientRect();
      const textHeight = containerRef.current.clientHeight - 500;
      const startingScrollPosition = 630;
      const animationWidth = 800;

      if (
        y < startingScrollPosition &&
        y > startingScrollPosition - textHeight
      ) {
        // Apply movement ratio to the animation width from textHeight vertical scroll
        const movement =
          ((startingScrollPosition - y) / textHeight) * animationWidth;

        setMilestoneBarPosition(milestoneBarPosition + movement);
      }
    }, [textRef]),
  );

  return (
    <div className="max-w-[1044px] my-[150px] mx-auto" ref={containerRef}>
      <div
        ref={textRef}
        className="font-heading text-2xl md:text-4xl lg:text-6xl lg:leading-[70px] m-4"
      >
        {tradWithColors(
          t('bottomImageText'),
          'children:mb-[50px] lg:children:mb-[100px]',
        )}
      </div>
      <div className="!sticky bottom-[-10px] lg:bottom-[-200px] overflow-hidden">
        <Milestones className="w-full" />
        <MilestoneBar
          style={{ left: `${milestoneBarPosition}px` }}
          className="absolute top-[-95px] z-10 w-full hidden lg:block"
        />
      </div>
    </div>
  );
};

export default FixedBottomImage;
