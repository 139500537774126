import React from 'react';
import classNames from 'classnames';

import Aerial from '../Text/Aerial';
import { MouseEffectImgProps } from '../MouseEffectImg';
import AnimatedBlob from '../AnimatedBlob';

interface HeroProps {
  title: JSX.Element | string;
  subTitle: JSX.Element | string;
  fullHeight?: boolean;
  url?: string;
  image?: MouseEffectImgProps;
  classNameImg?: string;
  displayImage?: boolean;
  blobFirstColor: string;
  blobSecondColor: string;
}

const Hero: React.FC<HeroProps> = ({
  title,
  subTitle,
  fullHeight,
  url,
  image,
  displayImage = true,
  classNameImg,
  children,
  blobFirstColor,
  blobSecondColor,
}) => {
  return (
    <div
      className={classNames('flex items-center py-12 mt-[100px]', {
        'lg:h-[calc(100vh-100px)]': fullHeight,
      })}
    >
      <div
        className={classNames(
          'flex',
          'flex-col',
          'justify-between',
          'lg:flex-row',
        )}
      >
        <div className="lg:w-2/3 animate-fadeIn">
          <h1 className="2xl:leading-[96px] xl:leading-[70px]">{title}</h1>
          <Aerial className="mt-10 mb-4 lg:w-[80%]">
            <div>{subTitle}</div>
          </Aerial>
          {children}
        </div>
        <div className="hidden lg:block">
          <AnimatedBlob
            image={image}
            blobFirstColor={blobFirstColor}
            blobSecondColor={blobSecondColor}
            classNameImg={classNameImg}
            url={url}
            displayImage={displayImage}
          />
        </div>
      </div>
    </div>
  );
};

export default Hero;
