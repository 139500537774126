import React from 'react';
import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { useTranslation } from 'react-i18next';

import { REALISATIONS } from '@/referentials/routes';

import Layout from '@/components/Layout';
import HeroMethodo from '@/components/Methodology/HeroMethodo';
import CtaBlock from '@/components/CtaBlock';
import Steps from '@/components/Methodology/Steps';
import FixedBottomImage from '@/components/Methodology/FixedBottomImage';
import Testimonial from '@/components/Testimonial';

import artelinkTestimonial from '@/referentials/testimonials/artelink';

export default function Methodology({ data }) {
  const { t } = useTranslation();

  return (
    <Layout
      title="Methodologie"
      description="Le charrette.club design, code et fait grandir des produits digitaux innovants."
      className="!overflow-visible"
    >
      <HeroMethodo />
      <Steps />
      <FixedBottomImage />
      <Testimonial testimonial={artelinkTestimonial()} />
      <CtaBlock
        title={t('ctaBlockTitle')}
        to={REALISATIONS}
        linkTitle={t('ctaBlockLink')}
        emoji={{
          image: getImage(data.computer),
          alt: 'computer icon',
        }}
      />
    </Layout>
  );
}

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["methodology", "general"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    computer: file(relativePath: { eq: "computer.png" }) {
      childImageSharp {
        gatsbyImageData(width: 300, placeholder: BLURRED)
      }
    }
  }
`;
